import React, { Component } from 'react';
import iconTubos from '../../images/tubos-sem-costura/icon_tubos.png';

import './TubosSemCostura.scss'

class TubosSemCostura extends Component {

    render() {
        return (
            <section className="product-specifications">
                <div className="container">
                    <div className="box">
                        <h3>ESPECIFICAÇÕES</h3>
                        <div className="specification-list">
                            <div className="specification-list-item left">
                                <div className="icon-wrapper">
                                    <img src={iconTubos} alt="" className="icon"/>
                                </div>
                                <div className="text">
                                    <p>Tipos de aço: 304L, 316L, entre outros.</p>
                                    <p>Normas: ASTM A-312</p>
                                </div>
                            </div>
                            <div className="specification-list-item left">
                                <div className="icon-wrapper">
                                    <img src={iconTubos} alt="" className="icon"/>
                                </div>
                                <div className="text">
                                    <p>Contamos com um vasto estoque de medidas em SCH10S, SCH40S, SCH80S, SCH160, XXS, entre outros.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default TubosSemCostura;
