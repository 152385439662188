import React, { Component } from 'react';
import logo from '../images/logo.png';
import imgCertification from '../images/certification.png';

class About extends Component {

    render() {
        return (
            <section className="about" id="empresa">

                <div className="container">
                    <div className="about-img-desktop"></div>
                    <div className="box">
                        <img src={logo} alt="" className="logo"/>

                        <div className="text">
                            <p>
                                A Jatinox é uma empresa de capital nacional distribuidora exclusiva
                                de matérias-primas em aço inoxidável. Possui o maior e mais variado estoque
                                de materiais planos (bobinas, fitas/tiras, chapas e chapas grossas) e não-planos
                                (barras, perfis e tubos) em todos os tipos, dimensões e acabamentos,
                                caracterizando-a
                                como a mais completa distribuidora de aços inoxidáveis da América do Sul. Oferece
                                ainda serviços de corte transversal de chapas e barras, além de slitter, blanques
                                e corte a plasma.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="about-img hidden-desktop" />

                <div className="quality-certification">
                    <div className="container">
                        <div className="box">
                            <a href="http://www.jatinox.com.br/certificado/certificado.pdf" target="_blank">
                                <img src={imgCertification} alt=""/>
                            </a>
                            <div className="text">
                                <p>
                                    A Jatinox possui um sistema gerencial voltado para a garantia de qualidade,
                                    com certificação ISO 9001:2015 DNVGL, tendo como foco principal a satisfação
                                    do cliente.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="our-units">
                    <div className="container">

                        <h3>CONHEÇA AS NOSSAS UNIDADES:</h3>

                        <div className="units">
                            <div className="unit">
                                <p className="title">
                                    Unidade Matriz - São Paulo
                                </p>
                                <p className="address">
                                    R. Roberto Koch, 363 - Ipiranga <br/>
                                    São Paulo/SP - CEP 04221-060 <br/>
                                    <span>Tel: (11) 2172-0405 | 2060-0405</span>
                                </p>
                            </div>
                            <div className="unit">
                                <p className="title">
                                    Unidade SP-II
                                </p>
                                <p className="address">
                                    R. Barão de Resende, 471 - Ipiranga <br/>
                                    São Paulo/SP - CEP: 04210-050 <br/>
                                    <span>Tel: (11) 2172-0405 | 2060-0405</span>
                                </p>
                            </div>
                            <div className="unit">
                                <p className="title">
                                    Unidade Indaiatuba
                                </p>
                                <p className="address">
                                    Av. Horst Frederico João Heer, 700 <br/>
                                    Europark Comercial - Indaiatuba/SP <br/>
                                    CEP: 13348-758 <br/>
                                    <span>Tel: (19) 3115-4300</span>
                                </p>
                            </div>
                            <div className="unit">
                                <p className="title">
                                    Unidade Paraná
                                </p>
                                <p className="address">
                                    Av. dos Pinheirais, 2209 - Capela Velha <br/>
                                    Araucária/PR - CEP: 83705-570 <br/>
                                    <span>Tel: (41) 3245-5111</span>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
        );
    }
}

export default About;
