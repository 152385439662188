import React, { Component } from 'react';
import '../style/App.scss';
import Header from './Header';
import ProductSpecifications from './ProductSpecifications';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import { getProduct } from '../products';

class App extends Component {

    constructor(props) {
        super(props);

        document.title = 'Jatinox - ' + getProduct().title;
    }

    handleBudgetClick = () => {

    };

    render() {
        return (
            <div className="wrapper">

                <Header onBudgetClick={this.handleBudgetClick}/>

                <div className="sections">

                    <ProductSpecifications/>

                    <About/>

                    <Contact/>

                </div>

                <Footer/>
            </div>
        );
    }
}

export default App;
