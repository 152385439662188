import React, { Component } from 'react';
import iconFita from '../../images/fita-tiras/icon_fitas.png';

import './FitaTiras.scss'

class FitaTiras extends Component {

    render() {
        return (
            <section className="product-specifications">
                <div className="container">
                    <div className="box">
                        <h3>ESPECIFICAÇÕES</h3>
                        <div className="specification-list">
                            <div className="specification-list-item left">
                                <div className="icon-wrapper">
                                    <img src={iconFita} alt="" className="icon"/>
                                </div>
                                <div className="text">
                                    <p>Espessuras da <span>Fita e Tira de aço Inox</span>: 0,40 MM a 3,00 MM</p>
                                    <p>Diâmetro interno: 400 ou 500 MM</p>
                                    <p>Tipos de Aço: 301/LN, 304/L/H/N, 310S, 316L, 317L, 321, 409, 410S, 420, 430, 439, 441, 444, 498, entre outros.</p>
                                </div>
                            </div>
                            <div className="specification-list-item right">
                                <div className="text">
                                    <p className="title">Qualidade da nossa fita e tira de aço inox:</p>
                                    <p>A Jatinox oferece a mais completa linha de aços inoxidáveis e fitas e tiras no mercado, obtidas por corte mecânico longitudinal ("slitter") de bobinas de aço inox com larguras maiores, assegurando alta qualidade e desempenho das mais variáveis aplicações.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FitaTiras;
