import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { getProduct } from '../products';

const Input = (props) => {

    const { type } = props;

    return (
        <div className="input-wrapper">
            {['text', 'email'].includes(type) && <InputMask type={type} placeholder={props.placeholder} name={props.name} onChange={(e) => props.onChange(props.name, e.target.value)} value={props.value} mask={props.mask} maskChar={props.maskChar} formatChars={props.formatChars} />}
            {type === 'textarea' && <textarea placeholder={props.placeholder} name={props.name} rows={props.rows} onChange={(e) => props.onChange(props.name, e.target.value)} value={props.value}/>}
        </div>
    );
};


class ContactForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            success: false,
            sending: false,
            formData: {
                name: '',
                company: '',
                email: '',
                phone: '',
                cellphone: '',
                subject: '',
                message: ''
            }
        };
    }

    handleInputChange = (field, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: value
            }
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            ...this.state.formData,
            hotsite: getProduct().title
        };

        const bodyFormData = new FormData();

        for (let key in data) {
            if (data[key].trim() === '') {
                this.setState({
                    success: false,
                    error: 'Por favor preencha todos os campos corretamente'
                });
                return;
            }

            bodyFormData.set(key, data[key]);
        }

        axios({
            method: 'post',
            url: '/contato.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(() => {
            this.setState({
                formData: {
                    name: '',
                    company: '',
                    email: '',
                    phone: '',
                    cellphone: '',
                    subject: '',
                    message: ''
                },
                sending: false,
                success: true,
                error: null
            })
        }).catch(() => {
            this.setState({
                sending: false,
                success: false,
                error: 'Erro ao enviar a mensagem. Tente novamente mais tarde.'
            });
        });
    };

    render() {
        return (
           <div className="contact-form-wrapper">
               <form action="" onSubmit={this.handleSubmit}>
                   <Input type="text" name="name" value={this.state.formData.name} placeholder="* Insira seu nome" onChange={this.handleInputChange} />
                   <Input type="text" name="company" value={this.state.formData.company} placeholder="* Insira o nome da empresa" onChange={this.handleInputChange} />
                   <Input type="email" name="email" value={this.state.formData.email} placeholder="* Insira seu e-mail" onChange={this.handleInputChange} />
                   <div className="two-cols">
                       <Input type="text" name="phone" value={this.state.formData.phone} placeholder="* Insira seu telefone" onChange={this.handleInputChange} mask="(99) 999999999" maskChar={null} />
                       <Input type="text" name="cellphone" value={this.state.formData.cellphone} placeholder="* Insira seu celular" onChange={this.handleInputChange} mask="(99) 999999999" />
                   </div>
                   <Input type="text" name="subject" value={this.state.formData.subject} placeholder="* Motivo do contato" onChange={this.handleInputChange} />
                   <Input type="textarea" name="message" value={this.state.formData.message} placeholder="* Insira sua mensagem" rows={10} onChange={this.handleInputChange} />
                   <input type="submit" value={this.state.sending ? 'ENVIANDO...' : 'ENVIAR'} className="btn-submit"/>
                   {this.state.error && <p className="error" style={{padding: '20px 0', color: '#f00', 'text-shadow': '2px 2px 10px rgba(0,0,0,0.7)'}}>{this.state.error}</p>}
                   {this.state.success && <p className="success" style={{padding: '20px 0', color: '#fff', 'text-shadow': '2px 2px 10px rgba(0,0,0,0.7)'}}>Mensagem enviada com sucesso!</p>}
               </form>
           </div>
        );
    }
}

export default ContactForm;
