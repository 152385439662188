import React, { Component } from 'react';
import iconChapaGrossa from '../../images/chapa-grossa/icon_chapa_grossa.png';

import './ChapaGrossa.scss'

class ChapaGrossa extends Component {

    render() {
        return (
            <section className="product-specifications">
                <div className="container">
                    <div className="box">
                        <h3>ESPECIFICAÇÕES</h3>
                        <div className="specification-list">
                            <div className="specification-list-item left">
                                <div className="icon-wrapper">
                                    <img src={iconChapaGrossa} alt="" className="icon"/>
                                </div>
                                <div className="text">
                                    <p><span>Espessura: </span>9,00 MM A 100,00 MM</p>
                                    <p><span>Largura: </span>Até 1.540 MM</p>
                                    <p><span>Comprimento: </span>Até 6.100 MM</p>
                                </div>
                            </div>
                            <div className="specification-list-item left">
                                <div className="icon-wrapper">
                                    <img src={iconChapaGrossa} alt="" className="icon"/>
                                </div>
                                <div className="text">
                                    <p>Tipos de Aço da Chapa Grossa de aço inox: 304/L/H, 310S, 316L, 410/D/S, 420, 41500, entre outros.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ChapaGrossa;
